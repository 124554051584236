const defaultConfig = {
  environmentId: "18905651-be30-4313-a398-3f24076969c3",
  responseType: "code",
  grantType: "authorization_code",
  redirectUri: "http://localhost:8080",
  logoutRedirectUri: "http://localhost:8080",
  scope: "openid profile address email phone",
  prompt: "login",
  tokenEndpointAuthMethod: "client_secret_post",
  maxAge: 3600,
  BASE_URL: "https://api.pingone.com",
  API_URI: "https://api.pingone.com/v1",
  AUTH_URI: "https://auth.pingone.com"
};

const getPingConfig = () => {
  const currUrl = window.location.href;
  /* eslint-disable no-underscore-dangle */
  const backendUrl =
    window.__ENV__?.REACT_APP_DNS_URL ||
    process.env.REACT_APP_PING_REDIRECT_URL;
  const gcpEnv = window.__ENV__?.REACT_APP_ENV || process.env.REACT_APP_ENV;
  /* eslint-enable no-underscore-dangle */

  const config = {
    ...defaultConfig,
    redirectUri: backendUrl,
    logoutRedirectUri: currUrl
  };

  if (gcpEnv === "prod") {
    config.environmentId = "d7a48707-7aff-411c-9da1-91490ca4c003";
  }

  if (gcpEnv === "local") {
    config.logoutRedirectUri = backendUrl ?? "";
  }

  return config;
};

export default getPingConfig;
