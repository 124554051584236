// eslint-disable-next-line no-unused-vars
import { IconButton, Tooltip } from '@mui/material';
import { styled } from '@mui/material/styles';
import * as XLSX from 'xlsx-js-style';
import { useDispatch } from 'react-redux';
import CloudUploadIcon from '@mui/icons-material/CloudUpload';
import GENERIC_CONSTANTS from '@constants/Regulations/Generic';
import { setPartList, logError } from '@redux/regulationMaintenanceSlice';
import './UploadPartListButton.css';

const VisuallyHiddenInput = styled('input')({
  clip: 'rect(0 0 0 0)',
  clipPath: 'inset(50%)',
  height: 1,
  overflow: 'hidden',
  position: 'absolute',
  bottom: 0,
  left: 0,
  whiteSpace: 'nowrap',
  width: 1,
});

const UploadPartListButton = () => {
  const dispatch = useDispatch();

  const handleFileInput = (e) => {
    if (e.target.files) {
      try {
        const file = e.target.files[0];

        const reader = new FileReader();
        reader.onload = (evt) => {
          try {
            const bstr = evt.target.result;
            const wb = XLSX.read(bstr, {
              type: 'binary',
              cellDates: true,
              cellNF: false,
              cellText: false,
            });
            const wsname = wb.SheetNames[0];
            const ws = wb.Sheets[wsname];
            const data = XLSX.utils.sheet_to_json(ws, {
              header: 1,
              raw: false,
              dateNF: 'mm-dd-yyyy',
              UTC: true,
            });
            data.forEach((d, index) => {
              if (index > 0 && d[0] && d[1]) {
                const fl = d[0].replace(/ /g, '');
                const part = d[1].replace(/ /g, '');
                data[index][0] = fl.replace(/[^a-zA-Z0-9]/g, '');
                data[index][1] = part.replace(/[^a-zA-Z0-9]/g, '');
              }
            });
            // validate the template header line
            dispatch(setPartList({ value: data }));
            dispatch(logError({}));
          } catch (error) {
            dispatch(setPartList({ value: [] }));
            dispatch(
              logError({
                name: 'regulationParts',
                value: error.message,
              }),
            );
          }
        };
        reader.readAsBinaryString(file);
      } catch (error) {
        dispatch(setPartList({ value: [] }));
        dispatch(logError({ name: 'regulationParts', value: error.message }));
      }
    }
  };

  const handleFileClick = (e) => {
    if (e.target.value) {
      e.target.value = '';
    }
  };

  return (
    <Tooltip title={GENERIC_CONSTANTS.UPLOAD} placement="top">
      <IconButton
        className="upload-part-list-button"
        id="regulatedPartsFile"
        size="large"
        component="label"
      >
        <CloudUploadIcon />
        <VisuallyHiddenInput
          id="uploadFileInput"
          onClick={handleFileClick}
          onChange={handleFileInput}
          type="file"
        />
      </IconButton>
    </Tooltip>
  );
};

export default UploadPartListButton;
