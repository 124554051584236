import { createSlice } from '@reduxjs/toolkit';
import { AUTHX_INIT } from '../../services/utils/AuthxConstants';

export const initialState = {
  authxStatus: AUTHX_INIT,
  authxToken: null,
  userInfo: null,
  userName: '',
  userId: null,
  userRoles: [],
  errors: [],
};

const getUserName = (usrInf) => {
  if (usrInf && 'User Name' in usrInf) {
    return usrInf['User Name'] || '';
  }
  return '';
};

const getUserId = (usrInf) => {
  if (usrInf && 'sub' in usrInf) {
    return usrInf.sub || null;
  }
  return null;
};

const getUserRoles = (usrInf) => {
  if (usrInf && 'role' in usrInf) {
    return usrInf.role || [];
  }
  return [];
};

const loginTokenSlice = createSlice({
  name: 'loginToken',
  initialState,
  reducers: {
    setAuthxStatus: (state, action) => ({
      ...state,
      authxStatus: action.payload,
    }),
    setUserInfo: (state, action) => ({
      ...state,
      userInfo: action.payload,
      userId: getUserId(action.payload),
      userName: getUserName(action.payload),
      userRoles: getUserRoles(action.payload),
    }),
    setAuthxToken: (state, action) => ({
      ...state,
      authxToken: action.payload,
    }),
    logError: (state, action) => ({
      ...state,
      errors: [...state.errors, action.payload],
    }),

    clearLoginToken: () => initialState,
  },
});

export const {
  clearLoginToken,
  logError,
  setAuthxToken,
  setAuthxStatus,
  setUserInfo,
} = loginTokenSlice.actions;

export default loginTokenSlice.reducer;
